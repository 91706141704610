var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wetModal',{attrs:{"id":"wet-price-error-modal","icon":"info","opened":_vm.value,"actions":[
    {
      title: _vm.stringsDone.stop_process,
      name: 'stop-process',
      event: () => _vm.stopFill()
    },
    {
      title: _vm.commonApp.cancel,
      name: 'close-outside',
      event: () => _vm.$emit('input', false)
    },
  ]},on:{"close":function($event){return _vm.$emit('input', false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.stringsDone.price_outside_risk_boundaries))]),_c('table-ui',{attrs:{"head":_vm.tableHead,"body":_vm.tableBody},scopedSlots:_vm._u([{key:"ceil-action",fn:function({ item, row }){return [_c('div',{staticClass:"d-flex justify-content-end"},[(item.text)?_c('button',{staticClass:"wet-button wet-button-up wet-button-small",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.onInsure(row)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }