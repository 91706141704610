<template>
  <wetModal
    id="wet-price-error-modal"
    icon="info"
    :opened="value"
    @close="$emit('input', false)"
    :actions="[
      {
        title: stringsDone.stop_process,
        name: 'stop-process',
        event: () => stopFill()
      },
      {
        title: commonApp.cancel,
        name: 'close-outside',
        event: () => $emit('input', false)
      },
    ]"
  >
    <template #content>
      <div class="mb-5">{{ stringsDone.price_outside_risk_boundaries }}</div>
      <table-ui
        :head="tableHead"
        :body="tableBody"
      >
        <template #ceil-action="{ item, row }">
          <div class="d-flex justify-content-end">
            <button
              v-if="item.text"
              type="button"
              class="wet-button wet-button-up wet-button-small"
              @click.prevent="onInsure(row)">
              {{ item.text }}
            </button>
          </div>
        </template>
      </table-ui>
    </template>
  </wetModal>
</template>

<script>
import { mapState } from 'vuex';
import { capitalize } from '@/filters.js';
import common from '@/mixins/common';

export default {
  mixins: [common],
  components: {
    tableUi: () => import(/* webpackChunkName: "Table" */ '@/components/ui/tableUi.vue'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    selectedParams: {
      type: Object,
      default: () => {},
    },
    optimizedData: {
      type: Array,
      default: () => [],
    },
    contractDays: {
      type: Number,
      default: NaN,
    },
    maxPayout: {
      type: Number,
      default: NaN,
    },
    dailyPayout: {
      type: Number,
      default: NaN,
    },
    isMaximalMode: {
      type: Boolean,
      default: false,
    },
    weatherType: {
      type: String,
      default: '',
    },
    minRiskDaysRatio: {
      type: Number,
      default: 0,
    },
    maxRiskDaysRatio: {
      type: Number,
      default: 1,
    },
    maxDailyPayout: {
      type: Number,
      default: NaN,
    },
    maxContractPayout: {
      type: Number,
      default: NaN,
    },
    currency: {
      type: String,
      default: '',
    },
    insurers: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      riskDaysTest: [],
      partners: ['Wakam'],
    };
  },
  computed: {
    ...mapState(['strings']),
    commonApp() {
      return this.strings.commonApp || {};
    },
    stringsDone() {
      return this.strings.done || {};
    },
    tableHead() {
      const tableHead = {
        threshold: { text: this.commonApp.weather_type[this.weatherType] },
        riskDays: { text: capitalize(this.stringsDone.plural_days?.[this.weatherDirection]) },
      };
      Object.values(this.insurers).forEach((insurer) => {
        const pnrName = this.format(this.stringsDone.insurer_price, insurer);
        tableHead[insurer] = { text: pnrName };
      });
      tableHead.action = { text: '' };
      return tableHead;
    },
    tableBody() {
      const currencyAppend = ` ${this.currency}`;
      const body = this.fillStack
        .map((row) => {
          const result = {
            threshold: { text: `${row.threshold} ${this.commonApp.unit[this.weatherType]}` },
            severity: { text: row.severity },
            riskDays: {
              text: this.isMaximalMode ? this.contractDays - row.risk_days : row.risk_days,
            },
            action: { text: this.commonApp.choose },
            dailyPayout: { text: row.dailyPayout },
            maxPayout: { text: row.maxPayout },
          };
          Object.entries(this.insurers).forEach(([id, insurer]) => {
            const price = row.bundle_price[id];
            result[insurer] = { text: price ? `${Math.ceil(price)}${currencyAppend}` : '-' };
          });
          return result;
        })
        .sort((a, b) => {
          const getSet = (set) => Object.values(this.insurers).map((insurer) => Number(set[insurer].text.replace(currencyAppend, ''))).filter((price) => price > 0);
          return Math.min(...getSet(a)) - Math.min(...getSet(b));
        });
      return body;
    },
    fillStack() {
      return this.optimizedData
        .filter((data) => data.f_in_risk_boundaries)
        .map((data) => ({
          ...data,
          dailyPayout: data.bundle_avg_daily_payout,
          maxPayout: data.bundle_maximal_payout,
        }));
    },
  },
  filters: {
    capitalize,
  },
  methods: {
    onInsure(row) {
      this.$emit('input', false);
      const selected = {
        severity: row.severity.text,
        days: row.riskDays.text,
        dailyPayout: row.dailyPayout.text,
        maxPayout: row.maxPayout.text,
      };
      this.$emit('select', selected);
    },
  },
};
</script>

<style>

</style>
